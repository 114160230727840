/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { Dictionary } from './Dictionary';

export interface MydawaCVEncounter {
    rdt_brand: string;
    rdt_model: string;
    rdt_condition: string;
    is_test_encounter?: boolean | null;
    encounter_start_time?: string | null;
    encounter_end_time?: string | null;
    rdt_lot_number?: string | null;
    rdt_expiry?: string | null;
    patient_id?: string | null;
    test_administrator_id?: string | null;
    test_administrator_type?: MydawaCVEncounter.test_administrator_type | null;
    test_administrator_interpretation?: MydawaCVEncounter.test_administrator_interpretation | null;
    test_administrator_interpretation_time?: string | null;
    test_administration_language?: string | null;
    image_reviewer_id?: string | null;
    image_reviewer_interpretation?: MydawaCVEncounter.image_reviewer_interpretation | null;
    image_reviewer_interpretation_time?: string | null;
    test_process_start_time?: string | null;
    patient_given_name?: string | null;
    patient_family_name?: string | null;
    patient_age?: number | null;
    patient_dob?: string | null;
    patient_gender?: MydawaCVEncounter.patient_gender | null;
    patient_sex?: MydawaCVEncounter.patient_sex | null;
    patient_ethnicity?: string | null;
    patient_pregnancy?: MydawaCVEncounter.patient_pregnancy | null;
    patient_phone_number?: string | null;
    testing_location?: {
        latitude?: string,
        longitude?: string,
        country?: string,
        county?: string,
        province?: string,
        city?: string,
    } | null;
    patient_symptoms?: Array<string> | null;
    client_info?: {
        user_agent?: string,
        device_platform?: string,
        device_os_version?: string,
        device_model?: string,
        app_name?: string,
        app_id?: string,
        app_version?: string,
    } | null;
    other_questions?: Dictionary<{
        question: string,
        options?: Array<string>,
        response: string,
    }> | null;
}

export namespace MydawaCVEncounter {

    export enum test_administrator_type {
        SELF_TESTER = 'self-tester',
        CLINICIAN = 'clinician',
        PHYSICIAN = 'physician',
        CHW = 'CHW',
        PHARM_TECH = 'pharm-tech',
    }

    export enum test_administrator_interpretation {
        POSITIVE = 'positive',
        NEGATIVE = 'negative',
        INVALID = 'invalid',
        UNINTERPRETABLE = 'uninterpretable',
    }

    export enum image_reviewer_interpretation {
        POSITIVE = 'positive',
        NEGATIVE = 'negative',
        INVALID = 'invalid',
        UNINTERPRETABLE = 'uninterpretable',
        DROPPED_JOURNEY = 'dropped-journey',
    }

    export enum patient_gender {
        MALE = 'male',
        FEMALE = 'female',
        NON_BINARY = 'non-binary',
        TRANSGENDER_MALE = 'transgender-male',
        TRANSGENDER_FEMALE = 'transgender-female',
        OTHER = 'other',
        NON_DISCLOSE = 'non-disclose',
        UNKNOWN = 'unknown',
    }

    export enum patient_sex {
        MALE = 'male',
        FEMALE = 'female',
        OTHER = 'other',
        UNKNOWN = 'unknown',
    }

    export enum patient_pregnancy {
        PREGNANT = 'pregnant',
        NOT_PREGNANT = 'not-pregnant',
        UNKNOWN = 'unknown',
    }


}
