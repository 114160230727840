import {
  Box,
  Container,
  Grid,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";

import Divider from "../../../components/ui/Divider";
import ExternalLink from "components/ui/ExternalLink";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rightSideLinks: {
      fontWeight: "normal",
      color: theme.palette.grey[700],
      "&:hover": {
        color: theme.palette.grey[900],
      },
    },
  })
);

const HealthPulseFooter = () => {
  const classes = useStyles();
  return (
    <>
      <Divider />
      <Container>
        <Grid container>
          <Grid item xs={6}>
            <Box fontSize={12} pb={1}>
              <div>©2020 HealthPulse@home</div>
              <div>Managed by Audere</div>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box fontSize={12} textAlign="right" pb={1}>
              <div>
                <ExternalLink
                  className={classes.rightSideLinks}
                  href="https://healthpulsenow.org/privacy-policy"
                >
                  Privacy Policy
                </ExternalLink>
              </div>
              <div>
                <ExternalLink
                  className={classes.rightSideLinks}
                  href="https://healthpulsenow.org/terms-of-use"
                >
                  Terms of Use
                </ExternalLink>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default HealthPulseFooter;
