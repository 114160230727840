import { getUserLocale } from "get-user-locale";

// Transform the detected user locale into something the FBT API can understand.
export default () => {
  const detectedLocale: string = getUserLocale();

  // the API can return 'en' or 'en-EN' which isn't great, so we
  // need to make a bet and suppose that 'en' means 'en-EN'.
  if (detectedLocale.length === 2) {
    return detectedLocale + "_" + detectedLocale.toUpperCase();
  } else if (detectedLocale.length === 5 && detectedLocale.charAt(2) === "-") {
    return detectedLocale.replace("-", "_");
  } else {
    console.warn(`Unexpected Locale format '${detectedLocale}'`);
    return "en_EN";
  }
};
