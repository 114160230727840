/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */


export interface InterpretDemoRequest {
    api_token: string;
    test_submitter: string;
    cv_service_id: InterpretDemoRequest.cv_service_id;
    provider: InterpretDemoRequest.provider;
}

export namespace InterpretDemoRequest {

    export enum cv_service_id {
        DRAGONTAIL_SERVER = 'dragontail-server',
        DRAGONTAIL = 'dragontail',
        FOUNDATION = 'foundation',
        GRANDTETON = 'grandteton',
    }

    export enum provider {
        MP_LAMBDA = 'mp-lambda',
    }


}
