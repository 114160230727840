import Firebase, {
  getFirebaseApp,
  getFirebaseAppSync,
} from "components/Firebase/Firebase";
import { useEffect, useState } from "react";

function getIsStagingFrontend(): boolean {
  return (
    document.location.hostname ===
      "openrdt-dashboard-staging.firebaseapp.com" ||
    document.location.hostname === "guide-staging.healthpulsenow.org"
  );
}

function getIsProductionFrontend(): boolean {
  return (
    document.location.hostname ===
      "openrdt-dashboard-production.firebaseapp.com" ||
    document.location.hostname === "guide.healthpulsenow.org"
  );
}

function getIsDevFrontend(): boolean {
  return process.env.NODE_ENV !== "production";
}

interface AppEnvironment {
  projectId: string;
  isStagingBackend: boolean;
  isProductionBackend: boolean;
  isDevFrontend: boolean;
  isStagingFrontend: boolean;
  isProductionFrontend: boolean;
}

// Will throw if app is not loaded yet.
export const getEnvironmentSync = (): AppEnvironment => {
  const app = getFirebaseAppSync();
  return {
    projectId: app.getProjectId() || "",
    isStagingBackend: app.isStagingBackend(),
    isProductionBackend: app.isProductionBackend(),
    isDevFrontend: getIsDevFrontend(),
    isStagingFrontend: getIsStagingFrontend(),
    isProductionFrontend: getIsProductionFrontend(),
  };
};

export const getEnvironmentAsync = async (): Promise<AppEnvironment> => {
  await getFirebaseApp();
  return getEnvironmentSync();
};

const useEnvironment = (): AppEnvironment & {
  isLoading: boolean;
} => {
  let app: Firebase | undefined;
  try {
    app = getFirebaseAppSync();
  } catch (exception) {
    app = undefined;
  }
  const isStagingFrontend = getIsStagingFrontend();
  const isProductionFrontend = getIsProductionFrontend();
  const isDevFrontend = process.env.NODE_ENV !== "production";

  const [isLoading, setIsLoading] = useState(!app);

  useEffect(() => {
    if (app) {
      if (isLoading) {
        setIsLoading(false);
      }
      return;
    }
    let mounted = true;
    const fetchAppConfig = async function() {
      if (!mounted) {
        return;
      }
      const loadedApp = await getFirebaseApp();
      if (!mounted) {
        return;
      }
      setIsLoading(!loadedApp);
    };

    fetchAppConfig();

    return () => {
      mounted = false;
    };
  }, [app, isLoading]);

  return {
    projectId: (app && app.getProjectId()) || "",
    isStagingBackend: !!(app && app.isStagingBackend()),
    isProductionBackend: !!(app && app.isProductionBackend()),
    isDevFrontend,
    isStagingFrontend,
    isProductionFrontend,
    isLoading: !app && isLoading,
  };
};

export default useEnvironment;
