/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */


export interface ExpertRead {
    email: string;
    interpretation: ExpertRead.interpretation;
    notes?: string;
}

export namespace ExpertRead {

    export enum interpretation {
        POSITIVE = 'positive',
        NEGATIVE = 'negative',
        BAD_PICTURE = 'badPicture',
        INVALID = 'invalid',
        POSITIVE_A = 'positiveA',
        POSITIVE_B = 'positiveB',
        POSITIVE_AB = 'positiveAB',
    }


}
