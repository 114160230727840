/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */


export enum ResponseCode {
    BAD_REQUEST = 'BAD_REQUEST',
    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
    INVALID_ACCESS_CODE = 'INVALID_ACCESS_CODE',
    INVALID_API_TOKEN = 'INVALID_API_TOKEN',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    INVALID_INVITE_CODE = 'INVALID_INVITE_CODE',
    NOT_FOUND = 'NOT_FOUND',
    PERMISSION_DENIED = 'PERMISSION_DENIED',
    RETRY = 'RETRY',
    SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
    SUCCESS = 'SUCCESS',
}