import { useSelector } from "store";
import { FedExConfirmationDetails } from "components/Shipping/FedEx/FedExTypes";

export type FedExState = {
  confirmation?: FedExConfirmationDetails;
};
export type FedExAction = {
  type: "FEDEX/CONFIRM_PICKUP";
  confirmation: FedExConfirmationDetails;
};

export function fedExReducer(
  state: FedExState = {},
  action: FedExAction
): FedExState {
  switch (action.type) {
    case "FEDEX/CONFIRM_PICKUP":
      return {
        ...state,
        confirmation: action.confirmation,
      };
    default:
      return state;
  }
}

export function useFedExState(): FedExState {
  return useSelector(state => state.fedEx);
}

export function confirmPickup(
  confirmation: FedExConfirmationDetails
): FedExAction {
  return {
    type: "FEDEX/CONFIRM_PICKUP",
    confirmation,
  };
}
