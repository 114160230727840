import { ExpertRead, ExpertReadRecord, Service } from "../epistore";

import { OpenAPI } from "../epistore/core/OpenAPI";
import { getEnvironmentSync } from "utils/useEnvironment";

const USE_REAL_API = true;

export function initializeEpistore(token: string) {
  setEpistoreBackend();
  OpenAPI.TOKEN = token;
}

export function setEpistoreBackend() {
  const environment = getEnvironmentSync();
  OpenAPI.BASE = environment.isProductionFrontend
    ? "https://epistore.auderenow.io"
    : "https://epistore.staging.auderenow.io";
}

const FAKE_INTERPRETER_EMAIL = "interpreter@fakeemail.auderenow.io";

const FAKE_RECORD: ExpertReadRecord = {
  uid: "14123e4567-e89b-12d3-a456-426614174000",
  created: "9/18/2020",
  modified: "foobar",
  email: FAKE_INTERPRETER_EMAIL,
  image_uri:
    "https://en.wikipedia.org/static/images/project-logos/enwiki-2x.png",
};

const recordsArray: ExpertReadRecord[] = [];

class FakeService extends Service {
  public static async getExpertReadRecords(params: {
    interpreted?: boolean;
    limit?: number;
  }): Promise<{
    records: ExpertReadRecord[];
    cursor: string;
    total_records: number;
  }> {
    if (params.limit !== undefined) {
      for (let i = params.limit; i <= params.limit + 50; i++) {
        recordsArray[i] = {
          ...FAKE_RECORD,
        };
        recordsArray[i].uid += "-" + i;
        i < 100
          ? (recordsArray[i].image_uri = `https://placekitten.com/600/${400 +
              i}`)
          : (recordsArray[i].image_uri = `https://placekitten.com/600/${100 +
              i}`);
        if (i % 2 === 0) {
          recordsArray[i].interpretation = ExpertRead.interpretation.POSITIVE;
        }
      }
    } else {
      for (let i = 0; i <= 50; i++) {
        recordsArray[i] = {
          ...FAKE_RECORD,
        };
        recordsArray[i].uid += "-" + i;
        i < 100
          ? (recordsArray[i].image_uri = `https://placekitten.com/600/${400 +
              i}`)
          : (recordsArray[i].image_uri = `https://placekitten.com/600/${100 +
              i}`);
      }
    }
    return {
      records: recordsArray,
      cursor: "some cursor",
      total_records: 1,
    };
  }

  public static async getExpertReadRecord({
    uid,
  }: {
    uid: string;
  }): Promise<ExpertReadRecord> {
    return recordsArray[recordsArray.findIndex(item => item.uid === uid)];
  }

  public static async postExpertReadRecord({
    projectId,
    uid,
    requestBody,
  }: {
    projectId: string;
    uid: string;
    requestBody: ExpertRead;
  }): Promise<ExpertRead> {
    console.log(`Updated ${uid} to ${requestBody.interpretation}.`);
    const record =
      recordsArray[recordsArray.findIndex(item => item.uid === uid)];
    record.interpretation = requestBody.interpretation;
    record.notes = requestBody.notes;
    record.email = requestBody.email;
    return {
      interpretation: requestBody.interpretation,
      notes: requestBody.notes,
      email: FAKE_INTERPRETER_EMAIL,
    };
  }
}

export const Epistore = USE_REAL_API ? Service : FakeService;
