import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@material-ui/core";
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useState,
} from "react";

import { fbt } from "fbt";

export type DialogProps = {
  onClose?: () => void;
  title?: ReactElement | string;
  // no need to set open to false
  parentWillUnmountOnClose?: boolean;
};

const AudereDialog: FunctionComponent<DialogProps> = props => {
  const [open, setOpen] = useState(true);
  const { children, onClose, title, parentWillUnmountOnClose } = props;

  const onContinueClick = useCallback(() => {
    onClose && onClose();
    !parentWillUnmountOnClose && setOpen(false);
  }, [onClose, parentWillUnmountOnClose]);

  const titleNode = title ? <DialogTitle>{title}</DialogTitle> : null;

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"sm"}>
      <Box p={1}>
        {titleNode}
        <DialogContent>
          {children &&
            React.Children.map(children, child => (
              <DialogContentText color="textPrimary">{child}</DialogContentText>
            ))}
        </DialogContent>
        <DialogActions>
          <Link variant="body1" component="button" onClick={onContinueClick}>
            <fbt desc="Dialog button label" project="audere">
              OK
            </fbt>
          </Link>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AudereDialog;
