import { CssBaseline, Theme, ThemeProvider } from "@material-ui/core";
import React, { useContext } from "react";

import { CustomerContext } from "components/App/DashboardApp";
import { getGlobalTheme } from "./AppThemes";

const themeCache: any = {};

const CustomerThemeProvider: React.FunctionComponent<{}> = props => {
  const customContext = useContext(CustomerContext);
  const contextName = customContext?.name || "generic";
  let customThemeOverride: Theme;
  if (!themeCache[contextName]) {
    customThemeOverride = customContext?.customThemeOverride || {};
    themeCache[contextName] = customThemeOverride;
  } else {
    customThemeOverride = themeCache[contextName];
  }

  return (
    <>
      <ThemeProvider theme={getGlobalTheme(customThemeOverride)}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </>
  );
};

export default CustomerThemeProvider;
