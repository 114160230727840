/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { AccountScheme } from '../models/AccountScheme';
import { AnonymousAuthResponse } from '../models/AnonymousAuthResponse';
import { AuthResponse } from '../models/AuthResponse';
import { BaobabClientLogRequest } from '../models/BaobabClientLogRequest';
import { BaobabEncounterRequest } from '../models/BaobabEncounterRequest';
import { BatchTestingGetIndexResponse } from '../models/BatchTestingGetIndexResponse';
import { BatchTestingGetPhotoMetadataResponse } from '../models/BatchTestingGetPhotoMetadataResponse';
import { BatchTestingGetPhotosByTagResponse } from '../models/BatchTestingGetPhotosByTagResponse';
import { CheckPhotoHashRequest } from '../models/CheckPhotoHashRequest';
import { CheckPhotoHashResponse } from '../models/CheckPhotoHashResponse';
import { CVBatchTestLogResultRequest } from '../models/CVBatchTestLogResultRequest';
import { CVBatchTestLogResultResponse } from '../models/CVBatchTestLogResultResponse';
import { CVBatchTestPhotoRequest } from '../models/CVBatchTestPhotoRequest';
import { CVBatchTestPhotoResponse } from '../models/CVBatchTestPhotoResponse';
import { CVBatchTestRunRequest } from '../models/CVBatchTestRunRequest';
import { CVBatchTestRunResponse } from '../models/CVBatchTestRunResponse';
import { CVEncounterByPatientIDResponse } from '../models/CVEncounterByPatientIDResponse';
import { CVEncounterCreateResponse } from '../models/CVEncounterCreateResponse';
import { CVEncounterGetImageResponse } from '../models/CVEncounterGetImageResponse';
import { CVEncounterNewImageRequest } from '../models/CVEncounterNewImageRequest';
import { CVEncounterNewImageResponse } from '../models/CVEncounterNewImageResponse';
import { CVEncounterUpdateResponse } from '../models/CVEncounterUpdateResponse';
import { CVGetProvenanceResponse } from '../models/CVGetProvenanceResponse';
import { CVGetSupportedRDTsResponse } from '../models/CVGetSupportedRDTsResponse';
import { CVListServicesResponse } from '../models/CVListServicesResponse';
import { CVPromoteReleaseCandidateRequest } from '../models/CVPromoteReleaseCandidateRequest';
import { CVPromoteReleaseCandidateResponse } from '../models/CVPromoteReleaseCandidateResponse';
import { Empty } from '../models/Empty';
import { ExpertRead } from '../models/ExpertRead';
import { ExpertReadRecord } from '../models/ExpertReadRecord';
import { InterpretDemoRequest } from '../models/InterpretDemoRequest';
import { InterpretDemoResponse } from '../models/InterpretDemoResponse';
import { MydawaCVEncounter } from '../models/MydawaCVEncounter';
import { MydawaCVEncounterInterpretationResponse } from '../models/MydawaCVEncounterInterpretationResponse';
import { MydawaCVEncounterUpdateRequest } from '../models/MydawaCVEncounterUpdateRequest';
import { PhotoUrlRequest } from '../models/PhotoUrlRequest';
import { PresharedKeyAuthRequest } from '../models/PresharedKeyAuthRequest';
import { PresharedKeyAuthResponse } from '../models/PresharedKeyAuthResponse';
import { PresignedUrl } from '../models/PresignedUrl';
import { SandboxCVEncounter } from '../models/SandboxCVEncounter';
import { SandboxCVEncounterInterpretationResponse } from '../models/SandboxCVEncounterInterpretationResponse';
import { SandboxCVEncounterUpdateRequest } from '../models/SandboxCVEncounterUpdateRequest';
import { VerifyRecordResponse } from '../models/VerifyRecordResponse';
import { WoodbridgeClientLogRequest } from '../models/WoodbridgeClientLogRequest';
import { WoodbridgeDeleteAccountRequest } from '../models/WoodbridgeDeleteAccountRequest';
import { WoodbridgeEncounterRequest } from '../models/WoodbridgeEncounterRequest';
import { catchGenericError } from '../core/ApiError';
import { request as __request } from '../core/request';

export class Service {

    /**
     * Get an anonymous auth token.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result AnonymousAuthResponse Successful operation
     * @throws ApiError
     */
    public static async audereinfraAnonymousAuthenticate({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<AnonymousAuthResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/audereinfra/anonymous_authenticate`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get the index that summarizes available batch-testing queries.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result BatchTestingGetIndexResponse Successful operation
     * @throws ApiError
     */
    public static async audereinfraBatchTestingGetIndex({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<BatchTestingGetIndexResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/audereinfra/batch_testing/index`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get metadata for a specific batch-testing photo.
     * @param xAuderePartnerKey Partner-specific API key
     * @param photoKey An opaque identifier for a particular photo returned by photos_by_tag.
     * @param xApiKey
     * @result BatchTestingGetPhotoMetadataResponse Successful operation
     * @throws ApiError
     */
    public static async audereinfraBatchTestingGetPhotoMetadata({
        xAuderePartnerKey,
        photoKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        photoKey: string,
        xApiKey: string,
    }): Promise<BatchTestingGetPhotoMetadataResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/audereinfra/batch_testing/photo_metadata/${photoKey}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get a list of batch testing photos that match the specified tag.
     * @param xAuderePartnerKey Partner-specific API key
     * @param tag The tag specifying which photos to return.
     * @param xApiKey
     * @result BatchTestingGetPhotosByTagResponse Successful operation
     * @throws ApiError
     */
    public static async audereinfraBatchTestingGetPhotosByTag({
        xAuderePartnerKey,
        tag,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        tag: string,
        xApiKey: string,
    }): Promise<BatchTestingGetPhotosByTagResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/audereinfra/batch_testing/photos_by_tag/${tag}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Create a batch test run
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @param requestBody
     * @result CVBatchTestRunResponse Successful operation
     * @throws ApiError
     */
    public static async audereinfraCvBatchTestRunCreate({
        xAuderePartnerKey,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
        requestBody: CVBatchTestRunRequest,
    }): Promise<CVBatchTestRunResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/audereinfra/cv_batch_test/create`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Log interpretation result for a batch test photo
     * @param xAuderePartnerKey Partner-specific API key
     * @param runUid CV batch run uid.
     * @param xApiKey
     * @param requestBody
     * @result CVBatchTestLogResultResponse Successful operation
     * @throws ApiError
     */
    public static async audereinfraCvBatchTestLogResult({
        xAuderePartnerKey,
        runUid,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        runUid: string,
        xApiKey: string,
        requestBody: CVBatchTestLogResultRequest,
    }): Promise<CVBatchTestLogResultResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/audereinfra/cv_batch_test/log/${runUid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Run a photo in a CV batch test and record results
     * @param xAuderePartnerKey Partner-specific API key
     * @param runUid CV batch run uid.
     * @param provider
     * @param xApiKey
     * @param requestBody
     * @result CVBatchTestPhotoResponse Successful operation
     * @throws ApiError
     */
    public static async audereinfraCvBatchTestPhoto({
        xAuderePartnerKey,
        runUid,
        provider,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        runUid: string,
        provider: ('lambda' | 'mp-lambda'),
        xApiKey: string,
        requestBody: CVBatchTestPhotoRequest,
    }): Promise<CVBatchTestPhotoResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/audereinfra/cv_batch_test/${runUid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            query: {
                'provider': provider,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Complete a batch test and update records
     * @param xAuderePartnerKey Partner-specific API key
     * @param runUid CV batch run uid.
     * @param xApiKey
     * @param requestBody
     * @result CVBatchTestRunResponse Successful operation
     * @throws ApiError
     */
    public static async audereinfraCvBatchTestRunComplete({
        xAuderePartnerKey,
        runUid,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        runUid: string,
        xApiKey: string,
        requestBody: CVBatchTestRunRequest,
    }): Promise<CVBatchTestRunResponse> {

        const result = await __request({
            method: 'put',
            path: `/v1/audereinfra/cv_batch_test/${runUid}/complete`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Fetches the list of CVaaS instances that are available.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result CVListServicesResponse Successful operation
     * @throws ApiError
     */
    public static async audereinfraCvListServices({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<CVListServicesResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/audereinfra/cv_services`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Retrieve a presigned photo upload URL corresponding to the given UID.
     * @param xAuderePartnerKey Partner-specific API key
     * @param photoUid The photo UID
     * @param xApiKey
     * @param requestBody
     * @result PresignedUrl Successful operation
     * @throws ApiError
     */
    public static async audereinfraGetPhotoUploadUrl({
        xAuderePartnerKey,
        photoUid,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        photoUid: string,
        xApiKey: string,
        requestBody: PhotoUrlRequest,
    }): Promise<PresignedUrl> {

        const result = await __request({
            method: 'post',
            path: `/v1/audereinfra/get_photo_upload_url/${photoUid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Health check endpoint for AudereInfra.
     * @param xApiKey
     * @result any Successful operation
     * @throws ApiError
     */
    public static async audereinfraHealthCheck({
        xApiKey,
    }: {
        xApiKey: string,
    }): Promise<any> {

        const result = await __request({
            method: 'get',
            path: `/v1/audereinfra/healthcheck`,
            headers: {
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Records which release candidate version has been promoted to production
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @param requestBody
     * @result CVPromoteReleaseCandidateResponse Successful operation
     * @throws ApiError
     */
    public static async audereinfraCvPromoteReleaseCandidate({
        xAuderePartnerKey,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
        requestBody: CVPromoteReleaseCandidateRequest,
    }): Promise<CVPromoteReleaseCandidateResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/audereinfra/promote_release_candidate`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * .
     * @param xAuderePartnerKey Partner-specific API key
     * @param code
     * @param xApiKey
     * @result any Successful operation
     * @throws ApiError
     */
    public static async audereInfraEcho({
        xAuderePartnerKey,
        code,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        code: number,
        xApiKey: string,
    }): Promise<any> {

        const result = await __request({
            method: 'post',
            path: `/v1/audereinfra/util/echo/${code}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get the index that summarizes available batch-testing queries.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result BatchTestingGetIndexResponse Successful operation
     * @throws ApiError
     */
    public static async baobabBatchTestingGetIndex({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<BatchTestingGetIndexResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/baobab/batch_testing/index`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get metadata for a specific batch-testing photo.
     * @param xAuderePartnerKey Partner-specific API key
     * @param photoKey An opaque identifier for a particular photo returned by photos_by_tag.
     * @param xApiKey
     * @result BatchTestingGetPhotoMetadataResponse Successful operation
     * @throws ApiError
     */
    public static async baobabBatchTestingGetPhotoMetadata({
        xAuderePartnerKey,
        photoKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        photoKey: string,
        xApiKey: string,
    }): Promise<BatchTestingGetPhotoMetadataResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/baobab/batch_testing/photo_metadata/${photoKey}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get a list of batch testing photos that match the specified tag.
     * @param xAuderePartnerKey Partner-specific API key
     * @param tag The tag specifying which photos to return.
     * @param xApiKey
     * @result BatchTestingGetPhotosByTagResponse Successful operation
     * @throws ApiError
     */
    public static async baobabBatchTestingGetPhotosByTag({
        xAuderePartnerKey,
        tag,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        tag: string,
        xApiKey: string,
    }): Promise<BatchTestingGetPhotosByTagResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/baobab/batch_testing/photos_by_tag/${tag}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Create or update a client log document with the given UID.
     * @param xAuderePartnerKey Partner-specific API key
     * @param uid The log UID
     * @param xApiKey
     * @param requestBody
     * @result Empty Successful operation
     * @throws ApiError
     */
    public static async baobabPutClientLog({
        xAuderePartnerKey,
        uid,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        uid: string,
        xApiKey: string,
        requestBody: BaobabClientLogRequest,
    }): Promise<Empty> {

        const result = await __request({
            method: 'put',
            path: `/v1/baobab/client_logs/${uid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Create or update an encounter document with the given UID.
     * @param xAuderePartnerKey Partner-specific API key
     * @param uid The encounter UID
     * @param xApiKey
     * @param requestBody
     * @result Empty Successful operation
     * @throws ApiError
     */
    public static async baobabPutEncounter({
        xAuderePartnerKey,
        uid,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        uid: string,
        xApiKey: string,
        requestBody: BaobabEncounterRequest,
    }): Promise<Empty> {

        const result = await __request({
            method: 'put',
            path: `/v1/baobab/encounters/${uid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Retrieve a presigned photo upload URL corresponding to the given UID.
     * @param xAuderePartnerKey Partner-specific API key
     * @param photoUid The photo UID
     * @param xApiKey
     * @param requestBody
     * @result PresignedUrl Successful operation
     * @throws ApiError
     */
    public static async baobabGetPhotoUploadUrl({
        xAuderePartnerKey,
        photoUid,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        photoUid: string,
        xApiKey: string,
        requestBody: PhotoUrlRequest,
    }): Promise<PresignedUrl> {

        const result = await __request({
            method: 'post',
            path: `/v1/baobab/get_photo_upload_url/${photoUid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Health check endpoint for Baobab.
     * @param xApiKey
     * @result any Successful operation
     * @throws ApiError
     */
    public static async baobabHealthCheck({
        xApiKey,
    }: {
        xApiKey: string,
    }): Promise<any> {

        const result = await __request({
            method: 'get',
            path: `/v1/baobab/healthcheck`,
            headers: {
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Authenticate a client based on a pre-shared key.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @param requestBody
     * @result PresharedKeyAuthResponse Successful operation
     * @throws ApiError
     */
    public static async baobabPresharedKeyAuth({
        xAuderePartnerKey,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
        requestBody: PresharedKeyAuthRequest,
    }): Promise<PresharedKeyAuthResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/baobab/preshared_key_authenticate`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Provides info about the specified backend resource.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xAudereQaKey QA API key
     * @param recordType The type of record to target.
     * @param uid Resource identifier
     * @param xApiKey
     * @result VerifyRecordResponse Successful operation
     * @throws ApiError
     */
    public static async baobabVerifyRecord({
        xAuderePartnerKey,
        xAudereQaKey,
        recordType,
        uid,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xAudereQaKey: string,
        recordType: ('client_log' | 'client_logs' | 'encounter' | 's3_photo'),
        uid: string,
        xApiKey: string,
    }): Promise<VerifyRecordResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/baobab/qa/verify_record/${recordType}/${uid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Audere-QA-Key': xAudereQaKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get an anonymous auth token.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result AnonymousAuthResponse Successful operation
     * @throws ApiError
     */
    public static async demositeAnonymousAuthenticate({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<AnonymousAuthResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/demosite/anonymous_authenticate`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Fetches the list of CVaaS instances that are available.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result CVListServicesResponse Successful operation
     * @throws ApiError
     */
    public static async demositeCvListServices({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<CVListServicesResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/demosite/cv_services`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Retrieve a presigned photo upload URL corresponding to the given UID.
     * @param xAuderePartnerKey Partner-specific API key
     * @param photoUid The photo UID
     * @param xApiKey
     * @param requestBody
     * @result PresignedUrl Successful operation
     * @throws ApiError
     */
    public static async demositeGetPhotoUploadUrl({
        xAuderePartnerKey,
        photoUid,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        photoUid: string,
        xApiKey: string,
        requestBody: PhotoUrlRequest,
    }): Promise<PresignedUrl> {

        const result = await __request({
            method: 'post',
            path: `/v1/demosite/get_photo_upload_url/${photoUid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Health check endpoint for Demosite.
     * @param xApiKey
     * @result any Successful operation
     * @throws ApiError
     */
    public static async demositeHealthCheck({
        xApiKey,
    }: {
        xApiKey: string,
    }): Promise<any> {

        const result = await __request({
            method: 'get',
            path: `/v1/demosite/healthcheck`,
            headers: {
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Run an interpretation for the CV demo site
     * @param xAuderePartnerKey Partner-specific API key
     * @param photoUid UID of the photo
     * @param xApiKey
     * @param requestBody
     * @result InterpretDemoResponse Successful operation
     * @throws ApiError
     */
    public static async demositeInterpretDemo({
        xAuderePartnerKey,
        photoUid,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        photoUid: string,
        xApiKey: string,
        requestBody: InterpretDemoRequest,
    }): Promise<InterpretDemoResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/demosite/interpret_demo/${photoUid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Query for multiple expertread records
     * @param projectId The project id, such as "thinkmd"
     * @param interpreted
     * @param limit
     * @param cursor
     * @result any Successful operation
     * @throws ApiError
     */
    public static async getExpertReadRecords({
        projectId,
        interpreted,
        limit,
        cursor,
    }: {
        projectId: string,
        interpreted?: boolean,
        limit?: number,
        cursor?: string,
    }): Promise<{
        records: Array<ExpertReadRecord>,
        cursor: string,
        total_records: number,
    }> {

        const result = await __request({
            method: 'get',
            path: `/v1/expertread/${projectId}/records`,
            query: {
                'interpreted': interpreted,
                'limit': limit,
                'cursor': cursor,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Info for a specific record.
     * @param projectId The project id, such as "thinkmd"
     * @param uid The uid of the record.
     * @result ExpertReadRecord Successful operation
     * @throws ApiError
     */
    public static async getExpertReadRecord({
        projectId,
        uid,
    }: {
        projectId: string,
        uid: string,
    }): Promise<ExpertReadRecord> {

        const result = await __request({
            method: 'get',
            path: `/v1/expertread/${projectId}/records/${uid}`,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Update expert read for a specific record.
     * @param projectId The project id, such as "thinkmd"
     * @param uid The uid of the record.
     * @param requestBody
     * @result ExpertRead Successful operation
     * @throws ApiError
     */
    public static async postExpertReadRecord({
        projectId,
        uid,
        requestBody,
    }: {
        projectId: string,
        uid: string,
        requestBody: {
            interpretation: ('positive' | 'negative' | 'badPicture' | 'invalid' | 'positiveA' | 'positiveB' | 'positiveAB'),
            notes?: string,
        },
    }): Promise<ExpertRead> {

        const result = await __request({
            method: 'post',
            path: `/v1/expertread/${projectId}/records/${uid}`,
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Health check endpoint for Mologic.
     * @param xApiKey
     * @result any Successful operation
     * @throws ApiError
     */
    public static async mologicHealthCheck({
        xApiKey,
    }: {
        xApiKey: string,
    }): Promise<any> {

        const result = await __request({
            method: 'get',
            path: `/v1/mologic/healthcheck`,
            headers: {
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Authenticate a client based on a pre-shared key.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @param requestBody
     * @result PresharedKeyAuthResponse Successful operation
     * @throws ApiError
     */
    public static async mologicPresharedKeyAuth({
        xAuderePartnerKey,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
        requestBody: PresharedKeyAuthRequest,
    }): Promise<PresharedKeyAuthResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/mologic/preshared_key_authenticate`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get the index that summarizes available batch-testing queries.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result BatchTestingGetIndexResponse Successful operation
     * @throws ApiError
     */
    public static async mydawaBatchTestingGetIndex({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<BatchTestingGetIndexResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/mydawa/batch_testing/index`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get metadata for a specific batch-testing photo.
     * @param xAuderePartnerKey Partner-specific API key
     * @param photoKey An opaque identifier for a particular photo returned by photos_by_tag.
     * @param xApiKey
     * @result BatchTestingGetPhotoMetadataResponse Successful operation
     * @throws ApiError
     */
    public static async mydawaBatchTestingGetPhotoMetadata({
        xAuderePartnerKey,
        photoKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        photoKey: string,
        xApiKey: string,
    }): Promise<BatchTestingGetPhotoMetadataResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/mydawa/batch_testing/photo_metadata/${photoKey}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get a list of batch testing photos that match the specified tag.
     * @param xAuderePartnerKey Partner-specific API key
     * @param tag The tag specifying which photos to return.
     * @param xApiKey
     * @result BatchTestingGetPhotosByTagResponse Successful operation
     * @throws ApiError
     */
    public static async mydawaBatchTestingGetPhotosByTag({
        xAuderePartnerKey,
        tag,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        tag: string,
        xApiKey: string,
    }): Promise<BatchTestingGetPhotosByTagResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/mydawa/batch_testing/photos_by_tag/${tag}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Health check endpoint for Mydawa.
     * @param xApiKey
     * @result any Successful operation
     * @throws ApiError
     */
    public static async mydawaHealthCheck({
        xApiKey,
    }: {
        xApiKey: string,
    }): Promise<any> {

        const result = await __request({
            method: 'get',
            path: `/v1/mydawa/healthcheck`,
            headers: {
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Fetches CV encounters with the specified patient_id.
     * @param xAuderePartnerKey Partner-specific API key
     * @param patientId
     * @param xApiKey
     * @result CVEncounterByPatientIDResponse Successful operation
     * @throws ApiError
     */
    public static async mydawaCvEncounterByPatientId({
        xAuderePartnerKey,
        patientId,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        patientId: string,
        xApiKey: string,
    }): Promise<CVEncounterByPatientIDResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/mydawa/hivst/encounter/by_patient_id/${patientId}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Creates a new CV-as-a-Service encounter.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @param requestBody
     * @result CVEncounterCreateResponse Successful operation
     * @throws ApiError
     */
    public static async mydawaCvEncounterCreate({
        xAuderePartnerKey,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
        requestBody: MydawaCVEncounter,
    }): Promise<CVEncounterCreateResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/mydawa/hivst/encounter/create`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Generates an upload URL for a new image.
     * @param xAuderePartnerKey Partner-specific API key
     * @param encounterId
     * @param imageId
     * @param xApiKey
     * @param requestBody
     * @result CVEncounterNewImageResponse Successful operation
     * @throws ApiError
     */
    public static async mydawaCvEncounterNewImage({
        xAuderePartnerKey,
        encounterId,
        imageId,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        encounterId: string,
        imageId: string,
        xApiKey: string,
        requestBody: CVEncounterNewImageRequest,
    }): Promise<CVEncounterNewImageResponse> {

        const result = await __request({
            method: 'put',
            path: `/v1/mydawa/hivst/encounter/${encounterId}/image/${imageId}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Fetches the URL of an image generated by the CV service
     * @param xAuderePartnerKey Partner-specific API key
     * @param encounterId
     * @param imageId
     * @param imageType
     * @param xApiKey
     * @result CVEncounterGetImageResponse Successful operation
     * @throws ApiError
     */
    public static async mydawaCvEncounterGetImage({
        xAuderePartnerKey,
        encounterId,
        imageId,
        imageType,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        encounterId: string,
        imageId: string,
        imageType: ('test_area' | 'enhanced_test_area' | 'original'),
        xApiKey: string,
    }): Promise<CVEncounterGetImageResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/mydawa/hivst/encounter/${encounterId}/image/${imageId}/${imageType}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Fetches the CV interpretation for all images in the encounter
     * @param xAuderePartnerKey Partner-specific API key
     * @param encounterId
     * @param xApiKey
     * @result MydawaCVEncounterInterpretationResponse Successful operation
     * @throws ApiError
     */
    public static async mydawaCvEncounterInterpretation({
        xAuderePartnerKey,
        encounterId,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        encounterId: string,
        xApiKey: string,
    }): Promise<MydawaCVEncounterInterpretationResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/mydawa/hivst/encounter/${encounterId}/interpretation`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Updates encounter metadata
     * @param xAuderePartnerKey Partner-specific API key
     * @param encounterId
     * @param xApiKey
     * @param requestBody
     * @result CVEncounterUpdateResponse Successful operation
     * @throws ApiError
     */
    public static async mydawaCvEncounterUpdate({
        xAuderePartnerKey,
        encounterId,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        encounterId: string,
        xApiKey: string,
        requestBody: MydawaCVEncounterUpdateRequest,
    }): Promise<CVEncounterUpdateResponse> {

        const result = await __request({
            method: 'put',
            path: `/v1/mydawa/hivst/encounter/${encounterId}/metadata`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Fetches the list of supported RDTs
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result CVGetSupportedRDTsResponse Successful operation
     * @throws ApiError
     */
    public static async mydawaCvGetSupportedRdTs({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<CVGetSupportedRDTsResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/mydawa/hivst/supported_rdts`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Fetches the provenance info
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result CVGetProvenanceResponse Successful operation
     * @throws ApiError
     */
    public static async mydawaCvGetProvenance({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<CVGetProvenanceResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/mydawa/provenance`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Health check endpoint for Sandbox.
     * @param xApiKey
     * @result any Successful operation
     * @throws ApiError
     */
    public static async sandboxHealthCheck({
        xApiKey,
    }: {
        xApiKey: string,
    }): Promise<any> {

        const result = await __request({
            method: 'get',
            path: `/v1/sandbox/healthcheck`,
            headers: {
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Fetches the provenance info
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result CVGetProvenanceResponse Successful operation
     * @throws ApiError
     */
    public static async sandboxCvGetProvenance({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<CVGetProvenanceResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/sandbox/provenance`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Creates a new CV-as-a-Service encounter.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @param requestBody
     * @result CVEncounterCreateResponse Successful operation
     * @throws ApiError
     */
    public static async sandboxCvEncounterCreate({
        xAuderePartnerKey,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
        requestBody: SandboxCVEncounter,
    }): Promise<CVEncounterCreateResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/sandbox/sandbox/encounter/create`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Fetches the CV interpretation for all images in the encounter
     * @param xAuderePartnerKey Partner-specific API key
     * @param encounterId
     * @param xApiKey
     * @result SandboxCVEncounterInterpretationResponse Successful operation
     * @throws ApiError
     */
    public static async sandboxCvEncounterInterpretation({
        xAuderePartnerKey,
        encounterId,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        encounterId: string,
        xApiKey: string,
    }): Promise<SandboxCVEncounterInterpretationResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/sandbox/sandbox/encounter/${encounterId}/analyze`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Generates an upload URL for a new image.
     * @param xAuderePartnerKey Partner-specific API key
     * @param encounterId
     * @param imageId
     * @param xApiKey
     * @param requestBody
     * @result CVEncounterNewImageResponse Successful operation
     * @throws ApiError
     */
    public static async sandboxCvEncounterNewImage({
        xAuderePartnerKey,
        encounterId,
        imageId,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        encounterId: string,
        imageId: string,
        xApiKey: string,
        requestBody: CVEncounterNewImageRequest,
    }): Promise<CVEncounterNewImageResponse> {

        const result = await __request({
            method: 'put',
            path: `/v1/sandbox/sandbox/encounter/${encounterId}/image/${imageId}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Fetches the URL of an image generated by the CV service
     * @param xAuderePartnerKey Partner-specific API key
     * @param encounterId
     * @param imageId
     * @param imageType
     * @param xApiKey
     * @result CVEncounterGetImageResponse Successful operation
     * @throws ApiError
     */
    public static async sandboxCvEncounterGetImage({
        xAuderePartnerKey,
        encounterId,
        imageId,
        imageType,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        encounterId: string,
        imageId: string,
        imageType: ('test_area' | 'enhanced_test_area' | 'original'),
        xApiKey: string,
    }): Promise<CVEncounterGetImageResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/sandbox/sandbox/encounter/${encounterId}/image/${imageId}/${imageType}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Updates encounter metadata
     * @param xAuderePartnerKey Partner-specific API key
     * @param encounterId
     * @param xApiKey
     * @param requestBody
     * @result CVEncounterUpdateResponse Successful operation
     * @throws ApiError
     */
    public static async sandboxCvEncounterUpdate({
        xAuderePartnerKey,
        encounterId,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        encounterId: string,
        xApiKey: string,
        requestBody: SandboxCVEncounterUpdateRequest,
    }): Promise<CVEncounterUpdateResponse> {

        const result = await __request({
            method: 'put',
            path: `/v1/sandbox/sandbox/encounter/${encounterId}/metadata`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Fetches the list of supported RDTs
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result CVGetSupportedRDTsResponse Successful operation
     * @throws ApiError
     */
    public static async sandboxCvGetSupportedRdTs({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<CVGetSupportedRDTsResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/sandbox/sandbox/supported_rdts`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Request that an account be deleted.
     * @param xAuderePartnerKey Partner-specific API key
     * @param scheme UserID scheme - how the rest of the path identifies the user
     * dxa_client_user_id/{uid}:
     * for callers that specify uid as doc.encounter.encounterInfo.userId
     * @param uid The client account UID
     * @param xApiKey
     * @param requestBody
     * @result Empty Successful operation
     * @throws ApiError
     */
    public static async woodbridgeDeleteAccount({
        xAuderePartnerKey,
        scheme,
        uid,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        scheme: AccountScheme,
        uid: string,
        xApiKey: string,
        requestBody: WoodbridgeDeleteAccountRequest,
    }): Promise<Empty> {

        const result = await __request({
            method: 'delete',
            path: `/v1/woodbridge/accounts/${scheme}/${uid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get an anonymous auth token.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result AnonymousAuthResponse Successful operation
     * @throws ApiError
     */
    public static async woodbridgeAnonymousAuthenticate({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<AnonymousAuthResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/woodbridge/anonymous_authenticate`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * @deprecated
     * Get an auth token.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result AuthResponse Successful operation
     * @throws ApiError
     */
    public static async woodbridgeAuthenticate({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<AuthResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/woodbridge/authenticate`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get the index that summarizes available batch-testing queries.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @result BatchTestingGetIndexResponse Successful operation
     * @throws ApiError
     */
    public static async woodbridgeBatchTestingGetIndex({
        xAuderePartnerKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
    }): Promise<BatchTestingGetIndexResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/woodbridge/batch_testing/index`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get metadata for a specific batch-testing photo.
     * @param xAuderePartnerKey Partner-specific API key
     * @param photoKey An opaque identifier for a particular photo returned by photos_by_tag.
     * @param xApiKey
     * @result BatchTestingGetPhotoMetadataResponse Successful operation
     * @throws ApiError
     */
    public static async woodbridgeBatchTestingGetPhotoMetadata({
        xAuderePartnerKey,
        photoKey,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        photoKey: string,
        xApiKey: string,
    }): Promise<BatchTestingGetPhotoMetadataResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/woodbridge/batch_testing/photo_metadata/${photoKey}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Get a list of batch testing photos that match the specified tag.
     * @param xAuderePartnerKey Partner-specific API key
     * @param tag The tag specifying which photos to return.
     * @param xApiKey
     * @result BatchTestingGetPhotosByTagResponse Successful operation
     * @throws ApiError
     */
    public static async woodbridgeBatchTestingGetPhotosByTag({
        xAuderePartnerKey,
        tag,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        tag: string,
        xApiKey: string,
    }): Promise<BatchTestingGetPhotosByTagResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/woodbridge/batch_testing/photos_by_tag/${tag}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Checks the hash of the specified photo.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @param requestBody
     * @result CheckPhotoHashResponse Successful operation
     * @throws ApiError
     */
    public static async woodbridgeCheckPhotoHash({
        xAuderePartnerKey,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
        requestBody: CheckPhotoHashRequest,
    }): Promise<CheckPhotoHashResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/woodbridge/check_photo_hash`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Create or update a client log document with the given UID.
     * @param xAuderePartnerKey Partner-specific API key
     * @param uid The log UID
     * @param xApiKey
     * @param requestBody
     * @result Empty Successful operation
     * @throws ApiError
     */
    public static async woodbridgePutClientLog({
        xAuderePartnerKey,
        uid,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        uid: string,
        xApiKey: string,
        requestBody: WoodbridgeClientLogRequest,
    }): Promise<Empty> {

        const result = await __request({
            method: 'put',
            path: `/v1/woodbridge/client_logs/${uid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Create or update an encounter document with the given UID.
     * @param xAuderePartnerKey Partner-specific API key
     * @param uid The encounter UID
     * @param xApiKey
     * @param requestBody
     * @result Empty Successful operation
     * @throws ApiError
     */
    public static async woodbridgePutEncounter({
        xAuderePartnerKey,
        uid,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        uid: string,
        xApiKey: string,
        requestBody: WoodbridgeEncounterRequest,
    }): Promise<Empty> {

        const result = await __request({
            method: 'put',
            path: `/v1/woodbridge/encounters/${uid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Retrieve a presigned photo upload URL corresponding to the given UID.
     * @param xAuderePartnerKey Partner-specific API key
     * @param photoUid The photo UID
     * @param xApiKey
     * @param requestBody
     * @result PresignedUrl Successful operation
     * @throws ApiError
     */
    public static async woodbridgeGetPhotoUploadUrl({
        xAuderePartnerKey,
        photoUid,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        photoUid: string,
        xApiKey: string,
        requestBody: PhotoUrlRequest,
    }): Promise<PresignedUrl> {

        const result = await __request({
            method: 'post',
            path: `/v1/woodbridge/get_photo_upload_url/${photoUid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Health check endpoint for Woodbridge.
     * @param xApiKey
     * @result any Successful operation
     * @throws ApiError
     */
    public static async woodbridgeHealthCheck({
        xApiKey,
    }: {
        xApiKey: string,
    }): Promise<any> {

        const result = await __request({
            method: 'get',
            path: `/v1/woodbridge/healthcheck`,
            headers: {
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Authenticate a client based on a pre-shared key.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xApiKey
     * @param requestBody
     * @result PresharedKeyAuthResponse Successful operation
     * @throws ApiError
     */
    public static async woodbridgePresharedKeyAuth({
        xAuderePartnerKey,
        xApiKey,
        requestBody,
    }: {
        xAuderePartnerKey: string,
        xApiKey: string,
        requestBody: PresharedKeyAuthRequest,
    }): Promise<PresharedKeyAuthResponse> {

        const result = await __request({
            method: 'post',
            path: `/v1/woodbridge/preshared_key_authenticate`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Api-Key': xApiKey,
            },
            body: requestBody,
        });

        catchGenericError(result);

        return result.body;
    }

    /**
     * Provides info about the specified backend resource.
     * @param xAuderePartnerKey Partner-specific API key
     * @param xAudereQaKey QA API key
     * @param recordType The type of record to target.
     * @param uid Resource identifier
     * @param xApiKey
     * @result VerifyRecordResponse Successful operation
     * @throws ApiError
     */
    public static async woodbridgeVerifyRecord({
        xAuderePartnerKey,
        xAudereQaKey,
        recordType,
        uid,
        xApiKey,
    }: {
        xAuderePartnerKey: string,
        xAudereQaKey: string,
        recordType: ('client_log' | 'client_logs' | 'encounter' | 's3_photo'),
        uid: string,
        xApiKey: string,
    }): Promise<VerifyRecordResponse> {

        const result = await __request({
            method: 'get',
            path: `/v1/woodbridge/qa/verify_record/${recordType}/${uid}`,
            headers: {
                'X-Audere-Partner-Key': xAuderePartnerKey,
                'X-Audere-QA-Key': xAudereQaKey,
                'X-Api-Key': xApiKey,
            },
        });

        catchGenericError(result);

        return result.body;
    }

}