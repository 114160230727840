import { AppConfig } from "../utils/ConfigTypes";
import { getAppConfig } from "../utils/AppConfig";
import { useSelector, AppThunk } from "store";

export type ConfigState = {
  loading: boolean;
  config?: AppConfig;
};

export type ConfigAction =
  | {
      type: "LOAD_CONFIG";
    }
  | {
      type: "CONFIG_LOADED";
      config: AppConfig;
    };

export function configReducer(
  state: ConfigState = { loading: false },
  action: ConfigAction
) {
  switch (action.type) {
    case "LOAD_CONFIG":
      return {
        loading: true,
      };
    case "CONFIG_LOADED":
      return {
        loading: false,
        config: action.config,
      };
    default:
      return state;
  }
}

export function loadConfig(): AppThunk {
  return async (dispatch, getState) => {
    if (getState().config.loading) {
      return;
    }
    dispatch({ type: "LOAD_CONFIG" });
    const config = await getAppConfig();
    dispatch({ type: "CONFIG_LOADED", config });
  };
}

export function useConfigState(): ConfigState {
  return useSelector(state => state.config);
}

export function useAppConfig(): AppConfig {
  const { config } = useSelector(state => state.config);
  if (!config) {
    throw new Error("Attempted to use app config before it was loaded");
  }
  return config;
}
