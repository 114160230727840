/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */


export interface CVEncounterNewImageRequest {
    content_type: CVEncounterNewImageRequest.content_type;
    photo_timestamp?: string;
}

export namespace CVEncounterNewImageRequest {

    export enum content_type {
        IMAGE_HEIC = 'image/heic',
        IMAGE_HEIF = 'image/heif',
        IMAGE_JPEG = 'image/jpeg',
        IMAGE_PNG = 'image/png',
    }


}
