import { EventEmitter } from "event-emitter3";

let emitter: EventEmitter;

export const getStepReadyEmitter = (): EventEmitter => {
  if (!emitter) {
    emitter = new EventEmitter();
  }
  return emitter;
};

export const setStepReady = (stepId: string, isReady: boolean) => {
  getStepReadyEmitter().emit(stepId, isReady);
};
