import "firebase/auth";
import "firebase/firestore";

import axios from "axios";
import { deviceDetect } from "react-device-detect";
import firebase from "firebase/app";
import { getFirebaseApp } from "components/Firebase/Firebase";
import { getStore } from "store";
import { uuid } from "uuidv4";

// TODO configure
const NHS_RDT_ID = "demo-rdt-malaria-hrp2-pf";
const NHS_LOINC_ID = "95209-3";
const NHS_UID = "d24dacc9-eb34-471d-8c22-d243802127fb";
const NHS_NAME = "NHS Demo";
const ML_PROTOCOL = "urn:auderenow.io/openrdt/interpreter/v1";
const ML_ENDPOINT =
  "https://api.auderenow.io/rdt-api/nhs/covid-antigen/interpret";
const LAT = 47.6205;
const LONG = 122.3493;

export type UploadStates =
  | "PHOTO_UPLOAD_START"
  | "PHOTO_UPLOAD_PROGRESS"
  | "PHOTO_UPLOAD_ERROR"
  | "PHOTO_UPLOAD_COMPLETE"
  | "PHOTO_UPLOAD_CANCELED";

export type PhotoUploadState = {
  uploadState?: UploadStates;
  error?: Error;
  uploadStatus?: UploadStatus;
  storageUrl?: string;
};

export type UploadStatus = {
  /**
   * The number of bytes that have been successfully uploaded so far.
   */
  bytesTransferred: number;
  /**
   * The total number of bytes to be uploaded.
   */
  totalBytes: number;
};

export interface PhotoUploadCallParams {
  imageAsFile?: File | null;
  imageAsURI?: string;
  onFileUploadComplete: (params: { imgUrl: string }) => void;
  onError: (uploadError: Error) => void;
  onFileUploadProgress: (uploadStatus: UploadStatus) => void;
}

export type PhotoUploadParams = {
  imageAsFile?: File | null;
  imageAsURI?: string;
  photoUploadCall: (params: PhotoUploadCallParams) => Promise<void>;
};

export type PhotoUploadAction =
  | { type: "PHOTO_UPLOAD_START" }
  | { type: "PHOTO_UPLOAD_PROGRESS"; uploadStatus: UploadStatus }
  | { type: "PHOTO_UPLOAD_ERROR"; uploadError: Error }
  | { type: "PHOTO_UPLOAD_COMPLETE"; storageUrl: string };

export function photoUploadReducer(
  state = {},
  action: PhotoUploadAction
): PhotoUploadState {
  switch (action.type) {
    case "PHOTO_UPLOAD_START":
      return {
        uploadState: "PHOTO_UPLOAD_START",
      };
    case "PHOTO_UPLOAD_PROGRESS":
      return {
        uploadState: "PHOTO_UPLOAD_PROGRESS",
        uploadStatus: action.uploadStatus,
      };
    case "PHOTO_UPLOAD_COMPLETE":
      return {
        uploadState: "PHOTO_UPLOAD_COMPLETE",
        storageUrl: action.storageUrl,
      };
    case "PHOTO_UPLOAD_ERROR":
      return {
        uploadState: "PHOTO_UPLOAD_ERROR",
        error: action.uploadError,
      };
    default:
      return state;
  }
}

export async function uploadPhoto(uploadParams: PhotoUploadParams) {
  const store = getStore();
  store.dispatch({ type: "PHOTO_UPLOAD_START" });

  uploadParams.photoUploadCall({
    ...uploadParams,
    onFileUploadComplete: (params: { imgUrl: string }) => {
      store.dispatch({
        type: "PHOTO_UPLOAD_COMPLETE",
        storageUrl: params.imgUrl,
      });
    },
    onError: (uploadError: Error) => {
      store.dispatch({ type: "PHOTO_UPLOAD_ERROR", uploadError });
    },
    onFileUploadProgress: (uploadStatus: UploadStatus) => {
      store.dispatch({
        type: "PHOTO_UPLOAD_PROGRESS",
        uploadStatus,
      });
    },
  });
}

// RDT Photo Upload functions

export async function uploadTestRunRDTPhoto(
  params: PhotoUploadCallParams & {
    testRunUID: string;
  }
) {
  const { imageAsFile, imageAsURI } = params;
  if (!imageAsFile && !imageAsURI) {
    throw Error("uploadPhoto needs either a File or a dataURI");
  }

  let file = imageAsFile;
  if (!file) {
    const filename = `${params.testRunUID}_${Date.now()}.png`;
    file = await dataURItoFile(imageAsURI!, filename);
  }

  if (!file) {
    throw Error("Error converting dataURI to Blob");
  }

  const store = getStore();

  const result = await uploadForInterpretation(file);
  if (!result) {
    return;
  }

  store.dispatch({
    type: "PHOTO_UPLOAD_COMPLETE",
    storageUrl: result.data.request_uid,
  });
  console.log(result);

  const rdtDetected = result?.data?.detection?.detected;
  let interpretation;
  let confidence;
  if (rdtDetected && result!.data!.results) {
    interpretation = result!.data!.results![NHS_LOINC_ID].result.toUpperCase();
    confidence = result!.data!.results![NHS_LOINC_ID].confidence;
  }
  store.dispatch({
    type: "SET_TESTRUN_ML_RESULT",
    mlResult: {
      rdtDetected,
      interpretation,
      confidence,
    },
  });
}

// Converts a data URI string into a File object.
async function dataURItoFile(dataURI: string, filename: string): Promise<File> {
  const rez = await fetch(dataURI);
  const blob: any = await rez.blob();
  blob.name = filename;
  blob.lastModifiedDate = new Date();
  return blob as File;
}

async function uploadForInterpretation(imageFile: File | Blob) {
  const formData = new FormData();

  formData.append("image", imageFile);
  const deviceInfo = deviceDetect();

  const metaObj = {
    protocol: ML_PROTOCOL,
    request_uid: uuid(),
    partner: {
      uid: NHS_UID,
      name: NHS_NAME,
    },
    rdt: {
      identifier: NHS_RDT_ID,
      loinc_tests: [NHS_LOINC_ID],
    },
    client: {
      hardware: `${deviceInfo.vendor} ${deviceInfo.model}`,
      os: `${deviceInfo.os} ${deviceInfo.osVersion}`,
      app: firebase.app().name,
      install: "web",
    },
    // TODO read from store
    activated_at: Date.now(),
    photographed_at: Date.now(),
    location: {
      latitude: LAT,
      longitude: LONG,
    },
    observations: {
      NHS_LOINC_ID: {
        loinc_test: NHS_LOINC_ID,
        loinc_answer: "unknown",
        result: "unknown",
      },
    },
  };

  const metaJson = JSON.stringify(metaObj);
  const metaBlob = new Blob([metaJson], {
    type: "application/json",
  });
  formData.append("metadata", metaBlob);

  const store = getStore();
  const config = {
    onUploadProgress: function(progressEvent: ProgressEvent) {
      store.dispatch({
        type: "PHOTO_UPLOAD_PROGRESS",
        uploadStatus: {
          bytesTransferred: progressEvent.loaded,
          totalBytes: progressEvent.total,
        },
      });
    },
  };
  try {
    return await axios.post(ML_ENDPOINT, formData, config);
  } catch (e) {
    store.dispatch({
      type: "PHOTO_UPLOAD_ERROR",
      uploadError: e,
    });
    return null;
  }
}

export async function updateTestRunRDTPhoto(params: {
  imgUrl: string;
  testRunUID: string;
  orgId: string;
  userUID: string;
}) {
  const firebaseApp = await getFirebaseApp();
  await firebaseApp.getTestRunByIDRef(params).update({
    photoResultURL: params.imgUrl,
  });
}
