/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */


export interface ExpertReadRecord {
    uid: string;
    created: string;
    modified: string;
    image_uri?: string;
    image_uri_firefly?: string;
    email?: string;
    interpretation?: ExpertReadRecord.interpretation;
    notes?: string;
}

export namespace ExpertReadRecord {

    export enum interpretation {
        POSITIVE = 'positive',
        NEGATIVE = 'negative',
        BAD_PICTURE = 'badPicture',
        INVALID = 'invalid',
        POSITIVE_A = 'positiveA',
        POSITIVE_B = 'positiveB',
        POSITIVE_AB = 'positiveAB',
    }


}
