import { Theme, createStyles, makeStyles } from "@material-ui/core";

export const useCenteredStyle = makeStyles((theme: Theme) =>
  createStyles({
    centeredContent: {
      textAlign: "center",
      marginBottom: ".5rem",
      "& img": {
        verticalAlign: "bottom",
      },
    },
  })
);

export default useCenteredStyle;
