import { AppThunk, useSelector } from "store";

import { FedExConfirmation } from "components/Shipping/FedEx/FedExTypes";
import { UNSET_PROFILE_ID } from "components/TestRun/TestRunConstants";
import { getFirebaseApp } from "components/Firebase/Firebase";

export interface TestRunStep {
  firstVisitedTime: number;
  lastVisitedTime: number;
}

export interface UserInterpretation {
  blueLine?: string;
  pinkLine?: string;
}

export interface Registration {
  barcode: string;
  registrationID: string;
  activated?: boolean;
  email?: string;
}

export interface NewTestLogsMetadata {
  appName: string;
  href: string;
  origin: string;
  referrer: string;
  token: string;
  TestRunUID: string;
  UTCTime: string;
  LocalTime: string;
  UserUID: string;
  userAgent: string;
}

export interface TestRunShortProfileDetails {
  firstname: string;
  // We won't store this in memory for privacy reason.
  // lastname: string;
  dob: string;
}

export type TestInterpretation = "POSITIVE" | "NEGATIVE" | "INVALID";

export interface MLResult {
  rdtDetected: boolean;
  interpretation?: TestInterpretation;
  confidence?: number;
}

export interface TestRun {
  timestamp?: number;
  uid: string;
  userUID?: string;
  profileUID: string;
  orgId?: string;
  lastStep: string;
  lastStepTimestamp: number;
  timerStart?: number;
  steps: Array<TestRunStep>;
  reportedSymptoms?: { [name: string]: boolean };
  testresult?: boolean;
  mlResult?: MLResult;
  photoResultURL?: string;
  userInterpretation?: UserInterpretation;
  registration?: Registration;
  registrationToken?: string;
  scheduledPickup?: FedExConfirmation;
  demoMode?: boolean;

  profileDetails?: TestRunShortProfileDetails;
}

export type TestRunState = {
  subscribed?: boolean;
  unsubscribe?: () => void;
  testRun?: TestRun;
};

export type TestRunAction =
  | {
      type: "SUBSCRIBE_TEST_RUN";
      unsubscribe?: () => void;
    }
  | {
      type: "UNSUBSCRIBE_TEST_RUN";
    }
  | {
      type: "LOAD_TEST_RUN";
      testRun: TestRun;
    }
  | {
      type: "NEXT_STEP";
      stepName: string;
    }
  | {
      type: "START_TIMER";
      timestamp: number;
    }
  | { type: "CLEAR_TIMER" }
  | { type: "SET_DEMO_MODE"; demoMode: boolean }
  | { type: "SET_USER_INTERPRETATION"; userInterpretation: UserInterpretation }
  | {
      type: "SET_REGISTRATION";
      registration: Registration;
      registrationToken: string;
    }
  | { type: "SET_SYMPTOMS"; reportedSymptoms: { [name: string]: boolean } }
  | { type: "SET_SCHEDULED_PICKUP"; scheduledPickup?: FedExConfirmation }
  | { type: "SET_TESTRUN_ML_RESULT"; mlResult: MLResult }
  | {
      type: "UPDATE_TESTRUN_PROFILE";
      profileUID: string;
      profileDetails: TestRunShortProfileDetails;
    };

export function testRunReducer(
  state: TestRunState = {},
  action: TestRunAction
): TestRunState {
  switch (action.type) {
    case "SUBSCRIBE_TEST_RUN":
      return {
        ...state,
        subscribed: true,
        unsubscribe: action.unsubscribe,
      };
    case "UNSUBSCRIBE_TEST_RUN": {
      const newState = { ...state, subscribed: false };
      delete newState.testRun;
      delete newState.unsubscribe;
      return newState;
    }
    case "LOAD_TEST_RUN": {
      const testRunState: TestRun = {
        ...(state.testRun as TestRun),
      };
      delete testRunState.profileDetails;
      return {
        ...state,
        testRun: {
          ...testRunState,
          ...action.testRun,
        },
      };
    }
    case "NEXT_STEP":
      return {
        ...state,
        testRun: {
          ...state.testRun!,
          lastStep: action.stepName,
        },
      };
    case "START_TIMER":
      return {
        ...state,
        testRun: {
          ...state.testRun!,
          timerStart: action.timestamp,
        },
      };
    case "CLEAR_TIMER":
      return {
        ...state,
        testRun: {
          ...state.testRun!,
          timerStart: undefined,
        },
      };
    case "SET_DEMO_MODE":
      return {
        ...state,
        testRun: {
          ...state.testRun!,
          demoMode: action.demoMode,
        },
      };
    case "SET_USER_INTERPRETATION":
      return {
        ...state,
        testRun: {
          ...state.testRun!,
          userInterpretation: action.userInterpretation,
        },
      };
    case "SET_REGISTRATION":
      return {
        ...state,
        testRun: {
          ...state.testRun!,
          ...action,
        },
      };
    case "SET_SYMPTOMS":
      return {
        ...state,
        testRun: {
          ...state.testRun!,
          ...action,
        },
      };
    case "SET_SCHEDULED_PICKUP":
      return {
        ...state,
        testRun: {
          ...state.testRun!,
          scheduledPickup: action.scheduledPickup,
        },
      };
    case "SET_TESTRUN_ML_RESULT":
      return {
        ...state,
        testRun: {
          ...state.testRun!,
          mlResult: action.mlResult,
        },
      };
    case "UPDATE_TESTRUN_PROFILE":
      return {
        ...state,
        testRun: {
          ...state.testRun!,
          profileUID: action.profileUID,
          profileDetails: action.profileDetails,
        },
      };
    default:
      return state;
  }
}

export function useTestRunState(): TestRunState {
  return useSelector(state => state.testRun);
}

export function subscribeToTestRun(params: {
  orgId: string;
  userUID: string;
  testRunUID: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { unsubscribe, testRun } = getState().testRun;
    if (unsubscribe && testRun && testRun?.uid !== params.testRunUID) {
      unsubscribe();
      await dispatch({
        type: "UNSUBSCRIBE_TEST_RUN",
      });
    }

    const firebaseApp = await getFirebaseApp();
    const testRunDoc = firebaseApp.getTestRunByIDRef(params);
    const newUnsubscribe = testRunDoc.onSnapshot(async testRunDetails => {
      const testRunData = testRunDetails.data() as TestRun;
      if (
        testRunData.profileUID &&
        testRunData.profileUID !== UNSET_PROFILE_ID
      ) {
        const profile = await firebaseApp.fetchUserProfile({
          ...params,
          profileUID: testRunData.profileUID,
        });
        if (profile?.firstname && profile?.dob) {
          testRunData.profileDetails = {
            firstname: profile.firstname,
            dob: profile.dob,
          };
        }
      }
      await dispatch({
        type: "LOAD_TEST_RUN",
        userUID: params.userUID,
        testRun: testRunData,
      });
    });
    await dispatch({
      type: "SUBSCRIBE_TEST_RUN",
      unsubscribe: newUnsubscribe,
    });
  };
}

export function createNewTestRun(params: {
  orgId: string;
  profileUID?: string;
  userUID: string;
  startStep: string;
}): AppThunk<Promise<string>> {
  return async dispatch => {
    const firebaseApp = await getFirebaseApp();
    const testRunUID = await firebaseApp.addTestRunToUser(params);
    const testRunDetails = await firebaseApp.fetchTestRunByID({
      orgId: params.orgId,
      testRunUID,
    });

    dispatch({
      type: "LOAD_TEST_RUN",
      userUID: params.userUID,
      ...testRunDetails,
    });
    return testRunUID;
  };
}

export function storeStepTimestamp(params: {
  stepName: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { stepName } = params;
    const { testRun } = getState().testRun;
    if (!testRun?.uid || !testRun.userUID || !testRun.orgId) {
      throw Error("No user or test run initialized");
    }
    const firebaseApp = await getFirebaseApp();
    Promise.all([
      firebaseApp.updateTestRunStepTimestamp({
        step: stepName,
        testRunUID: testRun.uid,
        orgId: testRun.orgId,
        userUID: testRun.userUID,
      }),
      firebaseApp.logTestRunNavEvent({
        step: stepName,
        testRunUID: testRun.uid,
        orgId: testRun.orgId,
        userUID: testRun.userUID,
      }),
    ]);

    dispatch({ type: "NEXT_STEP", stepName });
  };
}

export function startDemoMode(): AppThunk {
  return async (dispatch, getState) => {
    const { testRun } = getState().testRun;
    const uid = testRun?.uid;
    const userUID = testRun?.userUID;
    const orgId = testRun?.orgId;
    if (!uid || !userUID || !orgId) {
      throw Error("No user or test run initialized");
    }

    const firebaseApp = await getFirebaseApp();
    await firebaseApp.setTestRunDemoMode({
      testRunUID: uid,
      orgId,
      userUID,
    });
    dispatch({ type: "SET_DEMO_MODE", demoMode: true });
  };
}

export function updateUserInterpretation(params: {
  userInterpretation: UserInterpretation;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { testRun } = getState().testRun;
    const uid = testRun?.uid;
    const userUID = testRun?.userUID;
    const orgId = testRun?.orgId;
    const { userInterpretation } = params;
    if (!uid || !userUID || !orgId) {
      throw Error("No user or test run initialized");
    }

    const firebaseApp = await getFirebaseApp();
    await firebaseApp.updateTestRunUserInterpretation({
      testRunUID: uid,
      userUID,
      userInterpretation,
      orgId,
    });
    dispatch({ type: "SET_USER_INTERPRETATION", userInterpretation });
  };
}

export function updateRegistration(params: {
  barcode: string;
  registrationID: string;
  registrationToken: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { testRun } = getState().testRun;
    const uid = testRun?.uid;
    const userUID = testRun?.userUID;
    const orgId = testRun?.orgId;
    const { barcode, registrationID, registrationToken } = params;
    if (!uid || !userUID || !orgId) {
      throw Error("No user or test run initialized");
    }

    const firebaseApp = await getFirebaseApp();
    await firebaseApp.updateTestRunRegistration({
      barcode,
      registrationID,
      testRunUID: uid,
      userUID,
      registrationToken,
      orgId,
    });
    dispatch({
      type: "SET_REGISTRATION",
      registration: { registrationID, barcode },
      registrationToken,
    });
  };
}

export function updateSymptomsList(params: {
  reportedSymptoms: { [name: string]: boolean };
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { testRun } = getState().testRun;
    const uid = testRun?.uid;
    const userUID = testRun?.userUID;
    const orgId = testRun?.orgId;
    const { reportedSymptoms } = params;
    if (!uid || !userUID || !orgId) {
      throw Error("No user or test run initialized");
    }

    const firebaseApp = await getFirebaseApp();
    await firebaseApp.updateTestRunSymptomsList({
      reportedSymptoms,
      testRunUID: uid,
      userUID,
      orgId,
    });
    dispatch({
      type: "SET_SYMPTOMS",
      reportedSymptoms,
    });
  };
}

export function setScheduledPickup(
  confirmation: FedExConfirmation
): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { testRun } = getState().testRun;
    const uid = testRun?.uid;
    const userUID = testRun?.userUID;
    const orgId = testRun?.orgId;
    if (!uid || !userUID || !orgId) {
      throw Error("No user or test run initialized");
    }

    const firebaseApp = await getFirebaseApp();
    await firebaseApp.updateTestRunScheduledPickup(
      {
        testRunUID: uid,
        userUID,
        orgId,
      },
      confirmation
    );
    dispatch({
      type: "SET_SCHEDULED_PICKUP",
      scheduledPickup: confirmation,
    });
  };
}

export function setNewTestrunProfile(params: {
  firstname: string;
  lastname: string;
  dob: string;
}): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const { testRun } = getState().testRun;
    const { firstname, lastname, dob } = params;
    const testRunUID = testRun?.uid;
    const userUID = testRun?.userUID;
    const orgId = testRun?.orgId;
    if (!userUID || !orgId || !testRunUID) {
      throw Error("No user or test run initialized");
    }
    const firebaseApp = await getFirebaseApp();
    const profileDoc = await firebaseApp.addProfileToUser({
      userUID,
      orgId,
      profile: {
        firstname,
        lastname,
        dob,
      },
    });
    await firebaseApp.setTestRunProfile({
      orgId,
      profileUID: profileDoc.id,
      testRunUID,
    });

    dispatch({
      type: "UPDATE_TESTRUN_PROFILE",
      profileDetail: params,
      profileUID: profileDoc.id,
    });
  };
}

export function logNewTestRunMetadata(metadata: NewTestLogsMetadata): AppThunk {
  return async () => {
    const firebaseApp = await getFirebaseApp();
    await firebaseApp.logNewTestRunMetadata(metadata);
  };
}
