import { FORMID, TestRunParams } from "components/TestRun/TestRunConstants";
import {
  FedExError,
  FedExPickUpAddress,
  FedExShipperDetails,
  ProposedFedExPickupTime,
} from "./FedExTypes";
import { Link, createStyles, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

import { Address } from "./FedExCommon";
import { ErrorTypes } from "utils/useDataFetchApi";
import LoadingOverlay from "components/ui/LoadingOverlay";
import NextStepButton from "components/TestRun/NextStepButton";
import Paragraph from "components/ui/Paragraph";
import { Question } from "components/ui/Questionnaire/QuestionnaireTypes";
import Questionnaire from "components/ui/Questionnaire/Questionnaire";
import QuestionnaireInput from "components/ui/Questionnaire/QuestionnaireInput";
import Title from "components/ui/Title";
import { setStepReady } from "components/TestRun/TestRunStepReadyEmitter";
import useFedExFetchApi from "./useFedExFetchApi";
import { useParams } from "react-router-dom";
import { useTestRunState } from "store/testrun";

const useStyles = makeStyles(theme =>
  createStyles({
    address: {
      margin: "20px",
    },
    addressHeader: {
      fontStyle: "italic",
    },
  })
);

interface Props {
  originalAddress: FedExPickUpAddress;
  normalizedAddress: FedExPickUpAddress;
  shipperDetails: FedExShipperDetails;
  onEditAddress: (error?: FedExError) => void;
  onReceivePickupTimes: (data: {
    address: FedExPickUpAddress;
    proposedTimes: ProposedFedExPickupTime[];
    shipperDetails: FedExShipperDetails;
  }) => void;
}

const FedExSelectAddress = (props: Props) => {
  const { onEditAddress } = props;
  const onEditAddressClick = () => onEditAddress();
  const { step } = useParams<TestRunParams>();
  const [fetchState, { fetchFedExPickUpOptions }] = useFedExFetchApi();
  const [address, setAddress] = useState<FedExPickUpAddress>();
  const classes = useStyles();
  const testRunState = useTestRunState();

  useEffect(() => {
    if (fetchState.isLoading) {
      setStepReady(step, false);
    } else if (fetchState.isError) {
      setStepReady(step, true);
      switch (fetchState.errorType) {
        case ErrorTypes.NETWORK_ERROR:
          onEditAddress(FedExError.NETWORK_ERROR);
          break;
        default:
          onEditAddress(FedExError.NO_SERVICE);
      }
    } else if (fetchState.data) {
      props.onReceivePickupTimes({
        address: address!,
        proposedTimes: fetchState.data.result.options,
        shipperDetails: props.shipperDetails,
      });
    }
  }, [
    fetchState.data,
    fetchState.isError,
    fetchState.isLoading,
    props,
    onEditAddress,
    step,
    address,
    fetchState.errorType,
  ]);

  const registrationToken = testRunState.testRun?.registrationToken;
  const onSubmit = useCallback(
    (event: React.FormEvent, questions: { [key: string]: Question }) => {
      event.preventDefault();
      const address =
        questions.address.value === "original"
          ? props.originalAddress
          : props.normalizedAddress;
      setAddress(address);
      fetchFedExPickUpOptions({
        registrationToken: registrationToken!,
        address,
        timezoneOffsetMinutes: new Date().getTimezoneOffset(),
      });
    },
    [fetchFedExPickUpOptions, props, registrationToken]
  );
  const onFormReady = (ready: boolean) => {
    setStepReady(step, ready);
  };

  if (addressesMatch(props.originalAddress, props.normalizedAddress)) {
    return (
      <>
        <Title>Verify address</Title>
        <Paragraph>
          You entered the following address. Is this correct?
        </Paragraph>
        <Questionnaire
          formId={FORMID}
          onSubmit={onSubmit}
          onFormReady={onFormReady}
        >
          <Address
            address={props.originalAddress}
            className={classes.address}
          />
          <QuestionnaireInput
            type="hidden"
            label="Select pickup address"
            name="address"
            mandatory={true}
            value={"original"}
          />
          <Link onClick={onEditAddressClick}>Re-enter address</Link>
        </Questionnaire>
        <NextStepButton text="Confirm availability" />
        <LoadingOverlay
          isOpen={fetchState.isLoading}
          title="Finding available pickup times"
        />
      </>
    );
  }

  return (
    <>
      <Title>Verify address</Title>
      <Paragraph>
        We're having trouble verifying the pickup address. Please select the
        correct address below:
      </Paragraph>
      <Questionnaire
        formId={FORMID}
        onSubmit={onSubmit}
        onFormReady={onFormReady}
      >
        <QuestionnaireInput
          type="radio"
          label="Select pickup address"
          name="address"
          mandatory={true}
          selectOptions={[
            {
              value: "normalized",
              label: (
                <div className={classes.addressHeader}>Suggested address:</div>
              ),
              name: (
                <Address
                  address={props.normalizedAddress}
                  className={classes.address}
                />
              ),
            },
            {
              value: "original",
              label: (
                <div className={classes.addressHeader}>Original address:</div>
              ),
              name: (
                <Address
                  address={props.originalAddress}
                  className={classes.address}
                />
              ),
            },
          ]}
        />
        <Link onClick={onEditAddressClick}>Re-enter address</Link>
      </Questionnaire>
      <NextStepButton text="Confirm availability" />
      <LoadingOverlay
        isOpen={fetchState.isLoading}
        title="Finding available pickup times"
      />
    </>
  );
};

function addressesMatch(a: FedExPickUpAddress, b: FedExPickUpAddress): boolean {
  return (
    a.line.length === b.line.length &&
    a.line.every(
      (line, idx) => line.toLowerCase() === b.line[idx].toLowerCase()
    ) &&
    a.city.toLowerCase() === b.city.toLowerCase() &&
    zipCodesMatch(a.postalCode, b.postalCode) &&
    a.state.toLowerCase() === b.state.toLowerCase()
  );
}

function zipCodesMatch(a: string, b: string): boolean {
  // Two zipcodes match if they're equal, or if one is a ZIP+4 where the
  // first 5 digits is the other.
  // This assumes both zipcodes are well-formed ZIP or ZIP+4 codes
  return a.includes(b) || b.includes(a);
}

export default FedExSelectAddress;
