/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */


export interface CVBatchTestRunRequest {
    run_uid?: string;
    test_submitter?: string | null;
    test_description?: string | null;
    test_start?: string | null;
    test_end?: string | null;
    provenance?: {
        api?: string,
        cv_service?: string,
        client_info?: any,
    } | null;
    args?: {
        environment?: string | null,
        photo_source?: string | null,
        output_path?: string | null,
    } | null;
    debug_log?: any;
    cv_interpretation_source?: CVBatchTestRunRequest.cv_interpretation_source | null;
    cv_pipeline_info?: {
        codename?: ('denali' | 'dragontail-juniper' | 'dragontail-server' | 'dragontail-server-palmetto' | 'dragontail' | 'everest' | 'foundation' | 'fuji' | 'grandteton' | 'helens' | 'kilimanjaro' | 'matterhorn' | 'rainier' | 'teton') | null,
        version?: string | null,
    } | null;
}

export namespace CVBatchTestRunRequest {

    export enum cv_interpretation_source {
        CVAAS = 'cvaas',
        MOBILE = 'mobile',
        DOCKER = 'docker',
    }


}
